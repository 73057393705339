<template>
	<div v-if="ready" class="value backLink">
		<header v-if="showHeader">
			<div v-for="property in propertyList" :key="property.name" class="property">
				{{ property.localName }}
			</div>
		</header>

		<div class="collection">
			<router-link v-for="document in collection" :key="document._id" class="document" :to="link(document)">
				<input-combo
					v-for="property in propertyList"
					:key="property.name"
					v-model="document[property.name]"
					:property="property"
					:original-value="document[property.name]"
				/>
			</router-link>
		</div>
	</div>
</template>

<script>
import strings from '@/libRestQuery/js/utils/strings.js' ;

import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;

import mixin from './mixin' ;
import { inject } from 'vue' ;

export default {
	mixins: [mixin] ,
	props: {
		properties: {
			type: Array ,
			default: () => ['hid']
		} ,
		showHeader: {
			type: Boolean ,
			default: false
		}
	} ,
	setup( props , context ) {
		// FIXME: when in collection, there is no provided documents
		var originalDocument = inject( 'document' ) ;
		var id = originalDocument.value?._id ;

		if ( props.property.anyCollection ) {
			id += `@${originalDocument.value._collection}` ;
		}

		return SetupCollection( props , context , {
			collection: props.property.collection ,
			query: {
				filters: {
					[props.property.path]: {
						'$in': [id]
					}
				}
			} ,
			queryStats: false
		} ) ;
	} ,
	computed: {
		getColumnsLength: function() {
			return this.properties.length ;
		} ,
		propertyList: function() {
			return this.properties.map( property => this.schema.properties[property] ) ;
		}
	} ,
	methods: {
		link: function( document ) {
			return {
				params: {
					app: strings.capitalize( this.collectionName ) ,
					document: document.slugId || document._id
				}
			} ;
		}
	}
} ;
</script>
<style scoped>
.embedded {
	--columns: v-bind('getColumnsLength');
}
</style>