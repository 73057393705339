export default {
	props: {
		property: {
			type: Object ,
			required: true
		} ,
		originalValue: {
			required: true
		} ,
		disabled: {
			required: false ,
			default: false
		}
	} ,
	computed: {
		value: {
			get() {
				return this.originalValue ;
			} ,
			set( value ) {
				alert( 'Display property should not emit values !' ) ;
				console.log( 'Display property should not emit values !' , value ) ;
			}
		}
	}
} ;