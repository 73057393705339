<template>
	<div class="document">
		<h1>{{ i18n('returns.title') }}</h1>
		<div class="cols2">
			<section data-name:title>
				<header>{{ i18n('title.identification') }}</header>

				<div class="grid-align">
					<input-combo v-model="value['shippingNumber']" v-bind="auto('shippingNumber')" />
					<input-combo v-model="value['deposit']" v-bind="auto('deposit')" />
					<input-combo v-model="value['collectingWeek']" v-bind="auto('collectingWeek')" />
					<input-combo v-model="value['carouselCount']" v-bind="auto('carouselCount')" />
					<input-combo v-model="value['packageCount']" v-bind="auto('packageCount')" />
					<input-combo v-model="value['state']" v-bind="auto('state')" />



					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section data-name:metas class="locked">
				<header>{{ i18n('title.metas') }}</header>
				<div class="grid-align">
					<input-combo v-bind="auto('creationDate',{edit:false})" />
					<input-combo v-bind="auto('creationUser',{edit:false})" />
					<input-combo v-bind="auto('modificationDate',{edit:false})" />
					<input-combo v-bind="auto('modificationUser',{edit:false})" />
				</div>
			</section>
			<section v-grid-area:notes class="locked">
				<header>
					{{ i18n('title.comments') }}
					<router-link class="button" :to="createComment()">{{ i18n('btn-add') }}</router-link>
				</header>
				<div>
					<input-combo
						v-bind="auto('notes',{label:false})"
						:properties="[
							'note'
						]"
					/>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

<style scoped>
.document {
	grid-template: "title metas" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"title"
			"metas" / 1fr;
	}
}
</style>
