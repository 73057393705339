<template>
	<div class="login" :class="{ error: error }">
		<form @submit.prevent="submit">
			<h1 class="app-title">Murray [ ]</h1>
			<input v-model="login" type="text" name="email" :placeholder="i18n('placeholder.login')" />
			<input v-model="password" type="password" name="password" :placeholder="i18n('placehoder.password')" />
			<button class="button login-button">
				<span class="material-symbols loaderx">key</span>
				<span>{{ i18n('login') }}</span>
			</button>
		</form>
	</div>
</template>

<script>
import AccountStore from '@/libRestQuery/vue/store/Account/index.js' ;

export default {
	setup() {
		return { accountStore: AccountStore() } ;
	} ,
	data: function() {
		return {
			error: false ,
			login: null ,
			password: null
		} ;
	} ,
	methods: {
		submit: function() {
			this.accountStore.connect( this.login , this.password ) ;
		}
	}
} ;
</script>

<style scoped>
.login {
	font-size: 1.2em;
	display: grid;
	justify-content: center;
	align-content: center;
}
.login form {
	background-color: var(--color-background);
	color: var(--color-text);
}

input {
	color: var(--color-background);
	background-color: var(--color-text);
	text-align:center;
	border: solid 1px var(--color-smooth);

}

input,
.login-button {
	display: block;
	width: 100%;
	margin: 1em 0;
	height: 2em;
}

.login-button {
	background-color: var(--color-accent);
	color: var(--color-background);
	cursor: pointer;
	border-radius: 0;
	box-shadow: none;
	height: 3em;
	border-radius:1em;
	&:hover {
	background-color: var(--color-focus);
	color: var(--color-background);
}
&:active{
	box-shadow: 0px 3px 5px 0px #00000050 inset;
}
}



form {
	border: solid 1px var(--color-smooth);
	box-shadow: 2px 4px 6px 2px var(--color-smooth);
	min-width: 250px;
	max-width: 350px;
	padding: 1em;
	border-radius: 5px;
}

.login.error form {
	border: 1px solid var(--color-negative);
}

.app-title {
	font-family: 'Josefin Sans Variable', sans-serif;
	margin-bottom: 1em;
	opacity: 0.5;
	text-align: center;
	font-size: 3em;
}
</style>
