<template>
	<h1>{{ document['document.hid'] }}</h1>
	<div>{{ document['note'] }}</div>
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument
} ;
</script>