import fetchImage from '../fetchImage.js' ;

export default async function( data ) {
	var invoiceDate = new Intl.DateTimeFormat( 'fr-FR' , {
		year: 'numeric' ,
		month: '2-digit' ,
		day: '2-digit'
	} ).format( new Date( data.invoiceDate ) ) ;

	var totalQuantity = 0 ;
	var productsRows = data.products.map( product => {
		totalQuantity += parseInt( product.quantity , 10 ) || 0 ;

		return [
			{
				text: `${product.barcode}` ,
				border: [false , true , false , true] ,
				margin: [0 , 1 , 0 , 1]
			} ,
			{
				text: `${product.description}` ,
				border: [false , true , false , true] ,
				alignment: 'left' ,
				margin: [0 , 1 , 0 , 1]

			} ,
			{
				text: `${product.quantity || ''}` ,
				border: [false , true , false , true] ,
				alignment: 'center' ,
				margin: [0 , 1 , 0 , 1]

			} ,
			{
				text: `${product.unitPrice || ''}` ,
				border: [false , true , false , true] ,
				alignment: 'right' ,
				margin: [0 , 1 , 0 , 1] ,
				textTransform: 'uppercase'
			} ,
			{
				text: `${product.price || ''}` ,
				border: [false , true , false , true] ,
				alignment: 'right' ,
				margin: [0 , 1 , 0 , 1] ,
				textTransform: 'uppercase'
			}
		] ;
	} ) ;
	console.log( productsRows ) ;

	return {
		pageMargins: [40 , 150 , 40 , 180] ,
		info: {
			title: `123Bijoux - Facture ${data.invoiceNumber}` , // Client + Num Fact
			author: 'Application Murray' , //Nom application
			subject: 'Facture' ,
			keywords: `${data.amount}€` //Montant TTC de la facture
		} ,
		defaultStyle: {
			columnGap: 20
		} ,

		header: {
			margin: [40 , 40 , 40 , 40] ,
			columns: [
				{
					image: await fetchImage( '/logo-123bijoux.png' ) ,
					width: 150
				} ,
				{
					stack: [
						{
							text: 'Facture' ,
							color: '#333333' ,
							width: '*' ,
							fontSize: 28 ,
							bold: true ,
							alignment: 'right'
						} ,
						{
							text: `N°${data.invoiceNumber}` , //Num facture
							bold: true ,
							color: '#333333' ,
							fontSize: 11 ,
							alignment: 'right'

						} ,
						{
							text: `${invoiceDate}` ,
							bold: true ,
							color: '#333333' ,
							fontSize: 11 ,
							alignment: 'right'
						}
					]
				}

			]
		} ,
		content: [
			{
				columns: [
					{
						stack: [
							{
								text: '15 rue Roland Moreno\n17200 Saint Sulpice de Royan' ,
								margin: [0 , 0 , 0 , 10] ,
								alignment: 'left'
							} ,
							{
								text: '123bijoux@outlook.fr\nTél : 05 46 22 07 65\nFax : 05 67 20 64 36' ,
								alignment: 'left'
							}
						]
					} ,
					{
						stack: [
							{
								text: data.companyName , //Nom client
								bold: true ,
								alignment: 'left' ,
								margin: [40 , 0 , 0 , 10]
							} ,
							{
								text: `${data.address}\n${data.zipCode} ${data.city}` , //adresse client
								alignment: 'left' ,
								margin: [40 , 0 , 0 , 0]

							} ,
							{
								text: `\nCODE CLIENT ${data.client.clientCode}` , //Code client
								bold: true ,
								alignment: 'left' ,
								margin: [40 , 0 , 0 , 0]

							}
						]
					}
				]
			} ,
			{
				text: 'Détail facture' ,
				fontSize: 14 ,
				alignment: 'left' ,
				margin: [0 , 30 , 0 , 10]
			} ,

			{
				layout: {
					defaultBorder: false ,
					hLineWidth: function() {
						return 1 ;
					} ,
					vLineWidth: function() {
						return 1 ;
					} ,
					hLineColor: function( i ) {
						if ( i === 1 || i === 0 ) {
							return '#bfdde8' ;
						}
						return '#bfdde8' ;
					} ,
					vLineColor: function() {
						return '#bfdde8' ;
					} ,
					hLineStyle: function() {
						return null ;
					} ,
					paddingLeft: function() {
						return 10 ;
					} ,
					paddingRight: function() {
						return 10 ;
					} ,
					paddingTop: function() {
						return 2 ;
					} ,
					paddingBottom: function() {
						return 2 ;
					} ,
					fillColor: function() {
						return '#fff' ;
					}
				} ,

				table: {
					headerRows: 1 ,
					widths: ['auto' ,
						'*' ,
						60 ,
						60 ,
						60] ,
					body: [
						[
							{
								text: 'Code barre' ,
								fillColor: '#eaf2f5' ,
								border: [false , true , false , true] ,
								margin: [0 , 1 , 0 , 1] ,
								textTransform: 'uppercase'
							} ,
							{
								text: 'Description' ,
								fillColor: '#eaf2f5' ,
								border: [false , true , false , true] ,
								alignment: 'left' ,
								margin: [0 , 1 , 0 , 1] ,
								textTransform: 'uppercase'
							} ,
							{
								text: 'Quantité' ,
								fillColor: '#eaf2f5' ,
								border: [false , true , false , true] ,
								alignment: 'center' ,
								margin: [0 , 1 , 0 , 1] ,
								textTransform: 'uppercase'
							} ,
							{
								text: 'Unitaire €' ,
								fillColor: '#eaf2f5' ,
								border: [false , true , false , true] ,
								alignment: 'right' ,
								margin: [0 , 1 , 0 , 1] ,
								textTransform: 'uppercase'
							} ,
							{
								text: 'Total €' ,
								fillColor: '#eaf2f5' ,
								border: [false , true , false , true] ,
								alignment: 'right' ,
								margin: [0 , 1 , 0 , 1] ,
								textTransform: 'uppercase'
							}
						]
					].concat( productsRows )
				}
			}
		] ,
		footer: function( currentPage , pageCount /*, pageSize*/ ) {
			// you can apply any logic and return any valid pdfmake element
			return [
				{
					margin: [40 , 0 , 40 , 0] ,
					stack: [
						{
							columns: [
								{
									stack: [
										{
											text: 'Conditions de paiement' ,
											fontSize: 14 ,
											alignment: 'left' ,
											margin: [0 , 0 , 0 , 5]
										} ,
										{
											text: 'Iban : FR76 1551 9390 8700 0218 1950 159 - Code BIC : CMCIFR2A' ,
											fontSize: 10 ,
											border: [false , true , false , true] ,
											alignment: 'left' ,
											margin: [0 , 5 , 0 , 1]
										} ,
										{
											text: 'Code banque : 15519 - Code guichet : 39087 - N°de compte : 00021819501 - Clé : 59' ,
											fontSize: 8 ,
											border: [false , true , false , true] ,
											alignment: 'left' ,
											margin: [0 , 1 , 0 , 5]
										} ,
										{
											text: 'Règlement à réception de la facture. Passé la date d\'échéance, une pénalité de retard sera calculée aux taux légal en vigueur loi 2008/776 du 4 août 2008.' ,
											fontSize: 8 ,
											border: [false , true , false , true] ,
											alignment: 'left' ,
											margin: [0 , 3 , 0 , 2]
										}
									]
								} ,
								{
									width: 'auto' ,

									table: {

										headerRows: 1 ,
										widths: [75 , 75] ,

										body: [
											[

												{
													text: `Quantité : ${totalQuantity}` ,
													alignment: 'right' ,
													border: [false , false , false , false] ,
													fontSize: 10 ,
													colSpan: 2


												} ,
												{}

											] ,
											[
												{
													text: 'Total HT'
												} ,
												{
													text: `${data.excludingTaxAmount.toFixed( 2 )}€` , //Total HT
													alignment: 'right'
												}
											] ,
											[
												{ text: 'TVA (20%)' } ,
												{
													text: `${data.vatAmount.toFixed( 2 )}€` , //Montant TVA
													alignment: 'right'
												}
											] ,
											[
												{ text: 'Total TTC' , bold: true } ,
												{
													text: `${data.includingTaxAmount.toFixed( 2 )}€` , //Total TTC
													bold: true ,
													alignment: 'right'
												}
											]
										]
									}


								}

							]
						} ,
						{
							text: '123 Bijoux - SAS au capital de 2000€' ,
							fontSize: 12 ,
							border: [false , true , false , true] ,
							alignment: 'center' ,
							margin: [0 , 25 , 0 , 1]
						} ,
						{
							text: 'N°TVA : FR82 452 219 520 - Siret : 452 219 520 000 23 RCS Saintes - APE : 4684Z' ,
							fontSize: 10 ,
							border: [false , true , false , true] ,
							alignment: 'center' ,
							margin: [0 , 1 , 0 , 5]
						}

					]
				} ,
				{
					text: 'Page ' + currentPage.toString() + ' / ' + pageCount ,
					alignment: 'right' ,
					fontSize: 10 ,
					margin: [40 , 0 , 40 , 0]
				}

			] ;
		}
	} ;
};
