<template>
	<div class="document">
		<h1>{{ i18n('carousels.title') }}</h1>
		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('title.identification') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['name']" v-bind="auto('name')" />
					<input-combo v-model="value['season']" v-bind="auto('season')" />
					<input-combo v-model="value['year']" v-bind="auto('year')" />
					<input-combo v-model="value['rows']" v-bind="auto('rows')" />
					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section v-grid-area:metas class="locked">
				<header>{{ i18n('title.metas') }}</header>
				<div class="grid-align">
					<input-combo v-bind="auto('creationDate',{edit:false})" />
					<input-combo v-bind="auto('creationUser',{edit:false})" />
					<input-combo v-bind="auto('modificationDate',{edit:false})" />
					<input-combo v-bind="auto('modificationUser',{edit:false})" />
				</div>
			</section>
		</div>
		<section v-grid-area:composition>
			<header>{{ i18n('carousels.composition') }}</header>
			<input-combo v-model="value['products']" v-bind="auto('products',{label:false})" />
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas"
		"composition composition" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"composition" / 1fr;
	}
}
</style>

