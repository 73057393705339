<template>
	<pre class="value text" v-text="property.localIn?.[value] || value" />
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin]
} ;
</script>

<style scoped>
pre {
	margin: unset;
	display: inline-block;
}
</style>