<template>
	<input
		v-model.number="value"
		type="number"
		:name="property.name"
		class="value"
		:class="{hasFilters: hasFilters}"
	/>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: Object ,
			default: () => {}
		} ,
		operator: {
			type: String ,
			default: '$eq' ,
			validator( value ) {
				return [
					'$eq' ,
					'$lt' ,
					'$lte' ,
					'$gt' ,
					'$gte'
				].includes( value ) ;
			}
		}
	} ,
	computed: {
		value: {
			get() {
				return this.modelValue?.[this.operator] || null ;
			} ,
			set( value ) {
				if ( typeof this.modelValue === 'string' ) return this.emitValue( value ) ;


				if ( ['number' , 'integer' , 'float'].includes( this.property.type ) ) {
					value = Number.isFinite( value ) ? value : null ;
				}

				return this.emitValue( Object.assign( {} , this.modelValue , {
					[this.operator]: value
				} ) ) ;
			}
		}
	}
} ;
</script>
