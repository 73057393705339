<template>
	<div v-if="componantName" class="property" :class="[inheritedClass , componantClass]">
		<label v-if="label" :for="uuid" class="name">
			{{ labelValue }}
		</label>
		<component
			:is="componantName"
			:id="uuid"
			v-bind="$attrs"
			:disabled="isDisabled"
		/>
	</div>
</template>

<script>
import { useId } from 'vue' ;
import importGlobToObject from '@/libRestQuery/js/utils/importGlobToObject.js' ;
const display = import.meta.glob( './display/*.vue' , { eager: true } ) ;
const input = import.meta.glob( './input/*.vue' , { eager: true } ) ;

export default {
	components: {
		...importGlobToObject( display , 'display' ) ,
		...importGlobToObject( input , 'input' )
	} ,
	inheritAttrs: false ,
	props: {
		class: {
			type: String ,
			default: null
		} ,
		edit: {
			type: Boolean ,
			default: false
		} ,
		type: {
			type: String ,
			default: null
		} ,
		label: {
			type: [Boolean , String] ,
			default: false
		}
	} ,
	data: function() {
		return {
			uuid: useId()
		} ;
	} ,
	computed: {
		isDisabled: function() {
			return ( this.$attrs.property.immutable && typeof this.$attrs.originalValue !== 'undefined' )
				|| this.$attrs.disable
				|| this.$attrs.property.ui?.disabled ;
		} ,
		inheritedClass: function() {
			return this.class ;
		} ,
		componantClass: function() {
			return {
				[this.componantName]: true ,
				required: this.edit && this.$attrs.property.required
			} ;
		} ,
		componantName: function() {
			return this.getComponent( { ...this.$attrs.property , forceType: this.type } ) ;
		} ,
		labelValue: function() {
			return typeof this.label === 'string' ? this.label : this.$attrs.property.localName || this.$attrs.property.name ;
		}
	} ,
	methods: {
		getComponent: function( property ) {
			if ( ! property ) return 'display-debug' ;
			var component = this.edit ? this.getComponentInput( property ) : this.getComponentDisplay( property ) ;
			return component || 'display-debug' ;
		} ,
		getComponentInput: function( property ) {
			var component = 'input-' ;

			if ( property.forceType ) return component + property.forceType ;
			if ( property.ui?.type === 'color' ) return component += 'color' ;
			if ( property.ui?.type === 'textarea' ) return component += 'textarea' ;
			if ( property.in ) return component += 'select' ;

			switch( property.type ) {
				case 'string':
				case 'email':
					component += 'text' ;
					break ;

				case 'number' :
				case 'integer':
				case 'float':
					component += 'number' ;
					break ;

				case 'date':
				case 'link' :
				case 'multiLink' :
				case 'backLink':
				case 'file' :
					component += property.type ;
					break ;

				case 'boolean':
					component += 'checkbox' ;
					break ;

				case 'strictObject':
					if ( property.of ) component += 'object-of' ;
					break ;

				case 'array':
					if ( property.of ) component += 'embedded' ;
					break ;
				default:
					console.log( 'Warning: component input not found on property' , property.name , property ) ;
					component = null ;
					break ;
			}

			return component ;
		} ,
		getComponentDisplay: function( property ) {
			var component = 'display-' ;

			if ( property.forceType ) return component + property.forceType ;
			if ( property.ui?.type === 'color' ) return component += 'color' ;

			switch( property.type ) {
				case 'string':
				case 'email':
				case 'restQuery.slug':
				case 'restQuery.hid':
				case 'objectId':
					component += 'text' ;
					break ;

				case 'number':
				case 'integer':
				case 'float':
					component += 'number' ;
					break ;

				case 'date':
				case 'link' :
				case 'multiLink' :
				case 'backLink':
				case 'file' :
					component += property.type ;
					break ;

				case 'boolean':
					component += 'checkbox' ;
					break ;

				case 'strictObject':
					if ( property.of ) component += 'object-of' ;
					break ;

				case 'array':
					if ( property.of ) component += 'embedded' ;
					break ;

				default:
					console.log( 'Warning: component display not found on property' , property.name , property ) ;
					component = null ;
					break ;
			}

			return component ;
		}
	}
} ;
</script>
