<template>
	<input
		v-model="value"
		type="date"
		:name="property.name"
		min="2000-01-01"
		class="value"
		:class="{hasFilters: hasFilters}"
	/>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			// FIXME: stats too, should be only Object
			type: [String , Object] ,
			default: () => {}
		} ,
		operator: {
			type: String ,
			default: '$gt' ,
			validator( value ) {
				return [
					'$eq' ,
					'$lt' ,
					'$lte' ,
					'$gt' ,
					'$gte'
				].includes( value ) ;
			}
		}
	} ,
	computed: {
		value: {
			get() {
				// return this.modelValue?.[this.operator] || null ;
				// FIXME: just for statistics, to remove and rework stats filters instead
				return this.modelValue?.[this.operator] || this.modelValue || null ;
			} ,
			set( value ) {
				return this.emitValue( Object.assign( {} , this.modelValue , {
					[this.operator]: value
				} ) ) ;
			}
		}
	}
} ;
</script>
