import Invoices from './pdf_templates/Invoices.js' ;
import Deposits from './pdf_templates/Deposits.js' ;

export default {
	collection: {
		Invoices ,
		Deposits
	} ,
	hasTemplate: function( name ) {
		return typeof this.collection[name] !== 'undefined' ;
	} ,
	download: async function( name , data ) {
		const pdfMake = ( await import( 'pdfmake/build/pdfmake' ) ).default ;

		// Bug with vfs and vite, using cdn for now
		// const pdfFonts = ( await import( 'pdfmake/build/vfs_fonts' ) ).default ;
		// pdfMake.vfs = pdfFonts.pdfMake.vfs ;
		pdfMake.fonts = {
			Roboto: {
				normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf' ,
				bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf' ,
				italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf' ,
				bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
			}
		} ;

		if ( ! this.collection[name] ) {
			console.log( 'Warning: Export not found' ) ;
			return ;
		}

		pdfMake.createPdf( await this.collection[name]( data ) ).download() ;
	}
} ;
