<template>
	<div class="document">
		<h1>{{ i18n('deposits.title') }}</h1>
		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('title.identification') }}</header>

				<div class="grid-align">
					<input-combo v-model="value['client']" v-bind="auto('client')" />
					<input-combo v-model="value['orderNumber']" v-bind="auto('orderNumber')" placeholder="Automatique" />
					<input-combo v-model="value['state']" v-bind="auto('state')" />
					<input-combo v-model="value['orderDate']" v-bind="auto('orderDate')" />
					<input-combo v-model="value['departureDate']" v-bind="auto('departureDate')" />
					<input-combo v-model="value['deliveryDate']" v-bind="auto('deliveryDate')" />
					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section v-grid-area:metas class="locked">
				<header>{{ i18n('title.metas') }}</header>
				<div class="grid-align">
					<input-combo v-bind="auto('creationDate',{edit:false})" />
					<input-combo v-bind="auto('creationUser',{edit:false})" />
					<input-combo v-bind="auto('modificationDate',{edit:false})" />
					<input-combo v-bind="auto('modificationUser',{edit:false})" />
				</div>
			</section>

			<section v-grid-area:address>
				<header>{{ i18n('invoices.adresse') }}</header>
				<div class="grid-align">
					<div v-if="edit" class="property">
						<label>Select address</label>
						<select v-model="selectedAddress">
							<option v-for="(address,index) in addresses" :key="address.type" :value="index">{{ address.type }}</option>
						</select>
					</div>

					<input-combo v-model="value['companyName']" v-bind="auto('companyName')" />
					<input-combo v-model="value['address']" v-bind="auto('address')" />
					<input-combo v-model="value['zipCode']" v-bind="auto('zipCode')" />
					<input-combo v-model="value['city']" v-bind="auto('city')" />
					<input-combo v-model="value['country']" v-bind="auto('country')" />
				</div>
			</section>
		</div>
		<section v-grid-area:products>
			<header>{{ i18n('deposits.content') }}</header>
			<div>
				<input-combo v-model="value['carousel']" v-bind="auto('carousel')" :default-query="carouselQuery" />
				<input-combo v-model="value['products']" v-bind="auto('products')" />
			</div>
		</section>

		<section v-grid-area:comments>
			<header>{{ i18n('title.comments') }}</header>
			<div>
				<input-combo v-model="value['deliveryComment']" v-bind="auto('deliveryComment')" />
			</div>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;
import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;

export default {
	mixins: [mixin] ,
	data: function() {
		return {
			selectedAddress: null ,
			addresses: null ,
			carouselQuery: {
				filters: {
					archived: {
						$eq: false
					}
				}
			}
		} ;
	} ,
	watch: {
		'value.carousel': function() {
			if ( ! this.value.carousel || typeof this.value.carousel !== 'string' ) return ;

			Collections( 'Carousels' ).restQueryCollection.document( this.value.carousel ).get()
				.then( document => {
					this.value['products'] = document.products
						.filter( product => product.product?._id )
						.map( product => {
							return {
								product: { _id: product.product._id } ,
								quantity: product.quantity
							} ;
						} ) ;
				} ) ;
		} ,
		'value.client': function() {
			if ( ! this.edit ) return ;

			if ( ! this.value.client || typeof this.value.client !== 'string' ) return ;

			Collections( 'Organizations' ).restQueryCollection.document( this.value.client ).get()
				.then( document => {
					this.addresses = document.addresses ;
					this.value.companyName = document.companyName ;
				} ) ;
		} ,
		'selectedAddress': function() {
			var address = this.addresses[this.selectedAddress] ;
			this.value.address = address.address ;
			this.value.zipCode = address.zipCode ;
			this.value.city = address.city ;
			this.value.country = address.country ;
		}
	} ,
	mounted: function() {
		if ( this.value.client ) {
			Collections( 'Organizations' ).restQueryCollection.document( this.value.client?._id || this.value.client ).get()
				.then( document => {
					this.addresses = document.addresses ;
				} ) ;
		}
	}
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas"
		"products products"
		"comments comments" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"products"
			"comments" / 1fr;
	}
}
</style>