import { createApp } from 'vue' ;
import { createPinia } from 'pinia' ;

import App from '@/App.vue' ;
import router from './router/index.js' ;

import restQuery from '@/restQuery/index.js' ;
import '@/fonts/loader.js' ;

const app = createApp( App ) ;

app.use( createPinia() ) ;
app.use( router ) ;

import Debug from '@/libRestQuery/vue/components/Debug.vue' ;

import Collection from '@/libRestQuery/vue/components/Collection.vue' ;
import Document from '@/libRestQuery/vue/components/Document.vue' ;
import InputCombo from '@/libRestQuery/vue/components/InputCombo.vue' ;
import InputFilter from '@/libRestQuery/vue/components/InputFilter.vue' ;

import IconText from '@/components/Icon-text.vue' ;
import ButtonIcon from '@/components/Button-icon.vue' ;

app.component( 'Debug' , Debug ) ;

app.component( 'RestQueryCollection' , Collection ) ;
app.component( 'RestQueryDocument' , Document ) ;
app.component( 'InputCombo' , InputCombo ) ;
app.component( 'InputFilter' , InputFilter ) ;

app.component( 'IconText' , IconText ) ;
app.component( 'ButtonIcon' , ButtonIcon ) ;


app.mixin( {
	methods: {
		i18n: function( str , namespace ) {
			return restQuery.i18n.get( str , namespace ) ;
		} ,
		debug: function() {
			document.dispatchEvent( new Event( 'debug' ) ) ;
		}
	}
} ) ;

// app.directive( 'grid-area' , ( el , binding ) => {
// 	el.style.gridArea = binding.arg ;
// } ) ;

app.directive( 'grid-area' , {
	created( el , binding ) {
		el.style.gridArea = binding.arg ;
	}
} ) ;

app.mount( '#app' ) ;

export default app ;

