<template>
	<div class="value object-of">
		<input-combo
			v-for="(document,documentName) in value"
			:key="documentName"
			v-model="value[documentName]"
			:original-value="originalValue[documentName]"
			:edit="false"
			:label="true"
			:property="{ ...property.of, name:documentName }"
		/>
	</div>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin]
} ;
</script>
