<template>
	<h1>{{ document['name'] }}</h1>
	<div :style="{backgroundColor:document['color'],color:'white'}">{{ document['color'] }}</div>
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument
} ;
</script>