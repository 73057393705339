<template>
	<!--
	Use $route.params.app instead of $route.path/fullPath to not re-render
	on url changes
	-->
	<Debug />

	<div class="wrapper" :class="wrapperClass">
		<Navigation v-if="connected" />
		<main id="main" class="main">
			<component :is="getComponent" :key="$route.params.app" />
		</main>
	</div>
</template>

<script>
import AccountStore from '@/libRestQuery/vue/store/Account/index.js' ;
import Navigation from '../components/Navigation.vue' ;

import Config from '@/stores/Config.js' ;

import Home from './Home.vue' ;
import Login from './Login.vue' ;
import Infos from './Infos.vue' ;
import Account from './Account.vue' ;

import debugApp from './debugApp/Main.vue' ;
import zenparc from './zenparc/Main.vue' ;

export default {
	components: {
		Navigation ,

		Home ,
		Login ,
		Infos ,
		Account ,

		debugApp ,
		zenparc
	} ,
	setup() {
		return {
			accountStore: AccountStore() ,
			config: Config()
		} ;
	} ,
	computed: {
		wrapperClass: function() {
			return {
				connected: ! this.connected ,
				mobile: this.config.layout === 'Mobile' ,
				desktop: this.config.layout === 'Desktop'
			} ;
		} ,
		connected: function() {
			return !! this.accountStore.session ;
		} ,
		getComponent: function() {
			if ( this.$route.name !== 'Apps' ) {
				return this.$route.name ;
			}

			switch( this.$route.params.app ) {
				case 'Home':
					return this.$route.params.app ;
				default:
					// return 'debugApp' ;
					return 'zenparc' ;
			}
		}
	} ,
	watch: {
		'accountStore.session': {
			handler: function() {
				this.redirectOnSessionChange() ;
			} ,
			immediate: true
		}
	} ,
	methods: {
		redirectOnSessionChange: function() {
			if ( this.accountStore.session && this.$route.name === 'Login' ) {
				this.$router.push( { name: 'Home' } ) ;
			}
			else if ( ! this.accountStore.session && this.$route.meta.requiresAuth ) {
				this.$router.push( { name: 'Login' } ) ;
			}
		}
	}
} ;
</script>

<style scoped>
.wrapper {
	nav {
		position: fixed;
		z-index: 2;
		display: flex;
		flex-wrap: wrap;
		align-content: start;
	}
	main {
		display: grid;
		min-height: 100vh;
	}
}

.wrapper.desktop {
	nav {
		width: min-content;
		left: 0;
		height: 100vh;
	}

	main {
		margin-left: 3.5em;
	}
}

.wrapper.mobile {
	font-size: 16px;

	nav {
		height: min-content;
		width: 100vw;
		bottom: 0;
	}

	main {
		margin-left: 0em;
		margin-bottom: 3.5em;
	}
}
</style>