<template>
	<div v-if="ready && workingDocument" class="Document" :class="{edit: edit}">
		<component :is="actionComponent" :document="document" :schema="schema" :edit="edit" @action:exec="action" />

		<!-- FIXME: add form when edit === true -->
		<div class="content">
			<component
				:is="collectionComponent"
				v-model="workingDocument"
				:schema="schema"
				:document="document"
				:edit="edit"
				:class="{edit: edit}"
			/>
		</div>
	</div>
</template>

<script>
import pdf from '@/lib/pdf.js' ;

import importGlobToObject from '@/libRestQuery/js/utils/importGlobToObject.js' ;
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;

import Navigation from '@/stores/Navigation.js' ;

const customDocuments = import.meta.glob( './document/*.vue' , { eager: true } ) ;

export default {
	components: {
		...importGlobToObject( customDocuments )
	} ,
	props: {
		restQuery: {
			type: Object ,
			required: true
		}
	} ,
	setup: SetupDocument ,
	data: function() {
		return {
			edit: this.$route.query.method === 'EDIT' ,
			navigation: Navigation() ,
			collectionComponent: this.$options.components[this.restQuery.collection] ? this.restQuery.collection : 'Default' ,
			actionComponent: this.$options.components[`${this.restQuery.collection}-actions`] ? `${this.restQuery.collection}-actions` : 'Default-actions'
		} ;
	} ,
	watch: {
		'ready': {
			handler: function() {
				this.addEntry() ;
			} ,
			immediate: true
		}
	} ,
	methods: {
		action: function( name ) {
			if ( ! this[name] ) {
				console.log( `Action ${name} not found` ) ;
				return false ;
			}
			return this[name].call( this ) ;
		} ,
		exportPdf: function() {
			return pdf.download( this.collectionName , this.document ) ;
		} ,
		duplicate: async function() {
			this.createWorkingDocument()
				.then( workingDocument => {
					this.$router.push( {
						params: {
							app: this.collectionName ,
							document: 'NEW'
						} ,
						query: {
							method: 'EDIT' ,
							baseDocument: JSON.stringify( workingDocument )
						}
					} ) ;
				} ) ;
		} ,
		saveAndDisableEdit: async function() {
			this.save()
				.then( document => {
					if ( this.$route.params.document === document.slugId ) {
						this.edit = false ;
					}
					else {
						this.$router.push( {
							params: {
								app: this.collectionName ,
								document: document.slugId
							} ,
							query: {}
						} ) ;
					}
				} )
				.catch( error => alert( 'Document not saved\n' + error ) ) ;
		} ,
		switchEdit: async function() {
			let edit = ! this.edit ;
			if ( edit ) {
				await this.createWorkingDocument( true ) ;
				this.$router.push( { query: { method: 'EDIT' } } ) ;
			}
			else {
				this.$router.back() ;
			}
			this.edit = edit ;
			this.addEntry() ;
		} ,
		addEntry: function() {
			this.navigation.addEntry( {
				collection: this.restQuery.collection ,
				collectionPath: this.$route.fullPath ,
				document: this.restQuery.document ,
				documentPath: this.$route.fullPath ,

				path: this.$route.fullPath ,
				title: this.document?.hid || this.document?.slugId || this.i18n( 'new_document' ) ,
				icon: this.edit ? 'edit_document' : 'note'
			} ) ;
		}
	}
} ;
</script>

<style scoped src="./css/actions.css" />
<style src="./css/document.css" />
<!-- <style src="./css/document-alt.css" /> -->
