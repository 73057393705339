<template>
	<div class="home">
		<router-link v-for="app in appList" :key="app.name" class="app" :to="app.path || app.name">
			<div class="material-symbols" v-text="app.icon" />
			<div v-text="app.name" />
		</router-link>
	</div>
</template>

<script>
import AppList from '@/stores/AppList.js' ;
import Navigation from '@/stores/Navigation.js' ;

export default {
	data() {
		return {
			navigation: Navigation() ,
			appList: AppList().$state.filter( app => {
				return ! ( typeof app.dashboard !== 'undefined' && ! app.dashboard ) ;
			} )
		} ;
	} ,
	mounted: function() {
		this.navigation.clear() ;
	}
} ;
</script>

<style scoped>
.home {
	min-height: 100%;
	font-size: 1.2em;
	display: grid;
	grid-template-columns: repeat(auto-fill, 10em);
	justify-content: center;
	align-content: center;

	.app {
		text-align: center;
		padding: 1em;
		box-shadow: 0px 0px 1px 1px var(--color-smooth);
		margin:1em;
		background:var(--color-background);

		&:hover {
			color: var(--color-background);
			background-color: var(--color-focus);
		}

		.material-symbols {
			font-size: 2em;
		}
	}
	@media (max-width: 1000px) {
		padding-bottom: 6em;
	}
}
</style>