import { defineStore } from 'pinia' ;

export default defineStore( 'AppList' , {
	state: () => (
		[
			{
				name: 'Home' ,
				icon: 'home' ,
				dashboard: false
			} ,
			{
				path: 'Users?.archived.$eq=false&limit=30&sort.lastVisit=-1' ,
				name: 'Utilisateurs' ,
				icon: 'person' ,
				sidebar: false
			} ,
			{
				path: 'Products?.archived.$eq=false&limit=30&sort.hid=1' ,
				name: 'Produits' ,
				icon: 'diamond'
			} ,
			{
				path: 'Carousels?.archived.$eq=false&limit=30&sort.year=-1' ,
				name: 'Carousels' ,
				icon: 'inventory' ,
				sidebar: false
			} ,
			{
				path: 'Organizations?.archived.$eq=false&limit=30&sort.clientCode=-1' ,
				name: 'Organisations' ,
				icon: 'corporate_fare'
			} ,
			{
				path: 'Deposits?.archived.$eq=false&state.$in=[preparing]&limit=30&sort.hid=1' ,
				name: 'Préparations' ,
				icon: 'matter' ,
				sidebar: false
			} ,
			{
				path: 'Deposits?.archived.$eq=false&state.$in=[delivered]&limit=30&sort.hid=1' ,
				name: 'Dépôts' ,
				icon: 'local_shipping'
			} ,
			{
				path: 'Returns?.archived.$eq=false&state.$in=[requested]&limit=30&sort.collectingWeek=1' ,
				name: 'Retours' ,
				icon: 'undo'
			} ,
			{
				path: 'Invoices?.archived.$eq=false&limit=30&sort.invoiceDate=-1' ,
				name: 'Factures' ,
				icon: 'request_quote'
			} ,
			{
				path: 'Payments?.archived.$eq=false&limit=30&sort.paymentDate=-1' ,
				name: 'Paiements' ,
				icon: 'credit_score' ,
				sidebar: false
			} ,
			{
				path: 'Tags?.archived.$eq=false&limit=30&sort.hid=-1' ,
				name: 'Étiquettes' ,
				icon: 'Sell' ,
				sidebar: false
			} ,
			{
				path: 'Notes?.archived.$eq=false' ,
				name: 'Todo' ,
				icon: 'calendar_month'
			} ,
			{
				name: 'Statistics' ,
				icon: 'bar_chart'
			} ,
			{
				name: 'Tests' ,
				icon: 'bug_report' ,
				sidebar: false
			} ,
			{
				name: 'Infos' ,
				icon: 'help' ,
				sidebar: false
			} ,
			{
				name: 'Account' ,
				icon: 'settings' ,
				sidebar: false
			}
		]
	)
} ) ;
