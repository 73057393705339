<template>
	<div class="document">
		<h1>{{ i18n('invoices.title') }}</h1>
		<div class="cols2">
			<section v-grid-area:identification>
				<header>{{ i18n('title.identification') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['client']" v-bind="auto('client')" />
					<input-combo v-model="value['invoiceNumber']" v-bind="auto('invoiceNumber')" placeholder="Automatique" />
					<input-combo v-model="value['invoiceDate']" v-bind="auto('invoiceDate')" />
					<input-combo v-model="value['state']" v-bind="auto('state')" />
					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section v-grid-area:metas class="locked">
				<header>{{ i18n('title.metas') }}</header>
				<div class="grid-align">
					<input-combo v-bind="auto('creationDate',{edit:false})" />
					<input-combo v-bind="auto('creationUser',{edit:false})" />
					<input-combo v-bind="auto('modificationDate',{edit:false})" />
					<input-combo v-bind="auto('modificationUser',{edit:false})" />
				</div>
			</section>
			<section v-grid-area:total>
				<header>{{ i18n('invoices.total') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['excludingTaxAmount']" v-bind="auto('excludingTaxAmount')" placeholder="Automatique" />
					<input-combo v-model="value['vatRate']" v-bind="auto('vatRate')" placeholder="Automatique" />
					<input-combo v-model="value['vatAmount']" v-bind="auto('vatAmount')" placeholder="Automatique" />
					<input-combo v-model="value['includingTaxAmount']" v-bind="auto('includingTaxAmount')" placeholder="Automatique" />
					<input-combo v-model="value['amountPaid']" v-bind="auto('amountPaid')" placeholder="Automatique" />
				</div>
			</section>
			<section v-grid-area:address>
				<header>{{ i18n('invoices.adresse') }}</header>
				<div class="grid-align">
					<div v-if="edit" class="property">
						<label>Select address</label>
						<select v-model="selectedAddress">
							<option v-for="(address,index) in addresses" :key="address.type" :value="index">{{ address.type }}</option>
						</select>
					</div>

					<input-combo v-model="value['companyName']" v-bind="auto('companyName')" />
					<input-combo v-model="value['address']" v-bind="auto('address')" />
					<input-combo v-model="value['zipCode']" v-bind="auto('zipCode')" />
					<input-combo v-model="value['city']" v-bind="auto('city')" />
					<input-combo v-model="value['country']" v-bind="auto('country')" />
				</div>
			</section>

			<section v-grid-area:payments class="locked">
				<header>
					{{ i18n('invoices.payments') }}
					<router-link class="button" :to="createPayment()">{{ i18n('btn-add') }}</router-link>
				</header>
				<div>
					<input-combo
						v-bind="auto('payments',{label:false,edit:false})"
						:properties="[
							'amountPaid',
							'paymentDate',
							'paymentMethod'
						]"
					/>
				</div>
			</section>
		</div>
		<section v-grid-area:products>
			<header>{{ i18n('invoices.products') }}</header>
			<div>
				<input-combo
					v-model="value['products']"
					v-bind="auto('products',{label:false})"
				/>
			</div>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;
import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;

export default {
	mixins: [mixin] ,
	data: function() {
		return {
			selectedAddress: null ,
			addresses: null
		} ;
	} ,
	watch: {
		'value.client': function() {
			if ( ! this.edit ) return ;

			if ( ! this.value.client || typeof this.value.client !== 'string' ) return ;

			Collections( 'Organizations' ).restQueryCollection.document( this.value.client ).get()
				.then( document => {
					this.addresses = document.addresses ;
					this.value.companyName = document.companyName ;
				} ) ;
		} ,
		'selectedAddress': function() {
			var address = this.addresses[this.selectedAddress] ;
			this.value.address = address.address ;
			this.value.zipCode = address.zipCode ;
			this.value.city = address.city ;
			this.value.country = address.country ;
		} ,
		'value.vatRate': 'updateCustom' ,
		'value.products': {
			handler: function() {
				this.updateCustom() ;
			} ,
			deep: true
		}
	} ,
	mounted: function() {
		if ( this.value.client ) {
			Collections( 'Organizations' ).restQueryCollection.document( this.value.client?._id || this.value.client ).get()
				.then( document => {
					this.addresses = document.addresses ;
				} ) ;
		}
	} ,
	methods: {
		createPayment: function() {
			if ( ! this.document ) return {} ;
			return {
				params: {
					app: 'payments' ,
					document: 'NEW'
				} ,
				query: {
					method: 'EDIT' ,
					baseDocument: JSON.stringify( {
						client: { _id: this.document.client._id } ,
						invoice: { _id: this.document._id }
					} )
				}
			} ;
		} ,
		updateCustom: function() {
			if ( ! this.edit ) return ;

			var total = 0 ;
			for( let product of this.value.products ) {
				if ( product.product ) {
					Collections( 'Products' ).restQueryCollection.document( product.product ).get()
						.then( document => {
							product.description = document.hid ; // document.type + document.shortTypeCode + document.subtype ;
							product.barcode = document.barcode ;
							product.unitPrice = document.purchasePrice ;
							product.unitFinalPrice = document.sellingPrice ;
						} ) ;
				}
				let price = parseFloat( product.unitPrice || 0 ) * parseInt( product.quantity || 0 , 10 ) ;
				product.price = price.toFixed( 2 ) ;
				total += price ;
			}
			var excludingTaxAmount = total ;
			var vatAmount = excludingTaxAmount * ( this.value.vatRate || 0 ) / 100 ;
			var includingTaxAmount = excludingTaxAmount + vatAmount ;

			this.value.excludingTaxAmount = excludingTaxAmount.toFixed( 2 ) ;
			this.value.vatAmount = vatAmount.toFixed( 2 ) ;
			this.value.includingTaxAmount = includingTaxAmount.toFixed( 2 ) ;
		}
	}
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas"
		"total address"
		"products products" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas"
			"total"
			"address"
			"products" / 1fr;
	}
}
</style>