export default {
	props: {
		restQuery: {
			type: Object ,
			required: true
		}
	} ,
	methods: {
		auto: function( propertyName , options = {} ) {
			if ( ! this.schema.properties[propertyName] ) alert( `Unknown Property: ${propertyName}` ) ;

			return {
				property: this.schema.properties[propertyName] ,
				label: typeof options.label !== 'undefined' ? options.label : true ,
				edit: typeof options.edit !== 'undefined' ? options.edit : false ,
				originalValue: this.document?.[propertyName] ,
				placeholder: this.schema.properties[propertyName].required ? 'Champ obligatoire' : 'Champ facultatif'
			} ;
		}
	}
} ;