import { watch , inject , computed , ref } from 'vue' ;

export default function( props , context ) {
	const schema = inject( 'schema' ) ;

	const debounceSearchTimeout = ref( null ) ;

	const query = computed( {
		get() {
			return props.modelValue ;
		} ,
		set( query ) {
			// FIXME: Is generally not called, the watcher is the one that
			// trigger the emits
			console.log( 'AAAAAAA BUGGGGGGG!!!' ) ;

			context.emit( 'update:modelValue' , query ) ;
		}
	} ) ;

	/*
	for( let key of Object.keys( schema.value.filtrables ) ) {
		if ( ! query.value.filters[key] ) {
			query.value.filters[key] = {} ;
		}
	}
	*/

	const debounceSearch = computed( {
		get() {
			return query.value.search ;
		} ,
		set( search ) {
			clearTimeout( debounceSearchTimeout.value ) ;
			debounceSearchTimeout.value = setTimeout( () => {
				query.value.search = search ;
			} , 500 ) ;
		}
	} ) ;

	const filterList = computed( () => {
		if ( props.filters ) {
			return props.filters
				.filter( filter => {
					// return true ;
					let hasValue = schema.value.filtrables[filter.name] ;
					// console.log( hasValue , filter.name ) ;
					if ( ! hasValue ) alert( `Not filtrable property: ${filter.name}` ) ;

					return hasValue ;
				} ).map( filter => ( {
					...schema.value.filtrables[filter.name] ,
					operator: filter.operator || undefined ,
					label: filter.label
				} ) ) ;
		}
		return Object.values( schema.value.filtrables ) ;
	} ) ;

	watch( () => query , () => {
		context.emit( 'update:modelValue' , query.value ) ;
	} , { deep: true } ) ;

	return {
		schema ,
		debounceSearch ,
		query ,
		filterList
	} ;
}
