<template>
	<pre class="value">{{ jsonFormat( value ) }}</pre>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	methods: {
		jsonFormat: function( data ) {
			return JSON.stringify( data , null , '\t' ) ;
		}
	}
} ;
</script>

<style scoped>
.debug {
	box-shadow: 0 0 0 1px red inset;
}
</style>