export default {
	props: {
		property: {
			type: Object ,
			required: true
		}
	} ,
	emits: ['update:modelValue'] ,
	computed: {
		hasFilters: function() {
			if ( typeof this.value === 'object' && this.value !== null ) {
				return !! Object.values( this.value ).filter( a => !! a.length ).length ;
			}
			return typeof this.value !== 'undefined' && this.value !== null ;
		}
	} ,
	methods: {
		emitValue: function( value ) {
			console.log( '$emit filter' , value ) ;
			this.$emit( 'update:modelValue' , value ) ;
		}
	}
} ;
