<template>
	<div class="document">
		<h1>{{ i18n('notes.title') }}</h1>
		<div class="cols2">
			<section data-name:identification>
				<header>{{ i18n('title.identification') }}</header>
				<div class="grid-align">
					<input-combo v-model="value['document']" v-bind="auto('document')" />
					<!-- <input-combo v-model="value['type']" v-bind="auto('type')" /> -->
					<input-combo v-model="value['note']" v-bind="auto('note')" />
					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</section>
			<section v-grid-area:metas class="locked">
				<header>{{ i18n('title.metas') }}</header>
				<div class="grid-align">
					<input-combo v-bind="auto('creationDate',{edit:false})" />
					<input-combo v-bind="auto('creationUser',{edit:false})" />
					<input-combo v-bind="auto('modificationDate',{edit:false})" />
					<input-combo v-bind="auto('modificationUser',{edit:false})" />
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

<style scoped>
.document {
	grid-template:
		"identification metas" / 1fr 1fr;
}
@media (max-width: 1024px) {
	.document {
		grid-template:
			"identification"
			"metas" / 1fr ;
	}
}
</style>