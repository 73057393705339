<template>
	<div :style="{backgroundColor: value}" class="value color" v-text="value" />
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin]
} ;
</script>
