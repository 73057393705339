<template>
	<h1>{{ document.clientCode }} - {{ document.organizationName }}</h1>
	<div>
		{{ document.groupName }} ·
		{{ document.companyName }}
	</div>

	<hr />
	<div class="address">
		<div>
			<template v-if="document.addresses[0]">
				<div>{{ document.addresses[0].address }}</div>
				<div>{{ document.addresses[0].zipCode }}, {{ document.addresses[0].city }}</div>
			</template>

			<template v-if="document.communications[0]">
				<div>{{ document.communications[0].firstName }}</div>
				<div>{{ document.communications[0].phone }}</div>
			</template>
		</div>
		<hr />
	</div>
	<input-combo class="smaller as-tags" :link-to="null" v-bind="auto('tags',{label:false})" />
	<!--
	<div class="interests">
		<span v-if="document.interest['saison-été']" class="material-symbols">sunny</span>
		<span v-if="document.interest['saison-hiver']" class="material-symbols">ac_unit</span>
		<span v-if="document.interest['lunettes']" class="material-symbols">join_inner</span>
	</div>
-->
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument
} ;
</script>
